import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { LOCAL_KEY_DARK_MODE } from '../constants/local-storage.constant';
import { darkTheme } from '../themes/dark.theme';
import { createTheme } from '../themes/theme';
import { lightTheme } from '../themes/light.theme';

type Props = {
  children?: React.ReactNode;
};

interface ThemeContextData {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

const ThemeContext = createContext<ThemeContextData>({} as ThemeContextData);

const ThemeProvider: React.FC<Props> = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(lightTheme);
  const [isDarkMode, setIsDarkMode] = useState(false);

  const saveLocalHistory = () => {
    const stringBoolean = new Boolean(!isDarkMode).toString();
    localStorage.setItem(LOCAL_KEY_DARK_MODE, stringBoolean);
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    saveLocalHistory();
  };

  const saveAndActiveTheme = () => {
    setCurrentTheme(isDarkMode ? darkTheme : lightTheme);
  };

  const loadStorageTheme = () => {
    const storageIsDarkMode = localStorage.getItem(LOCAL_KEY_DARK_MODE);
    if (storageIsDarkMode === 'true' && !isDarkMode) {
      setIsDarkMode(storageIsDarkMode === 'true');
    }
  };

  const createCurrentTheme = () => {
    return createTheme(currentTheme, isDarkMode);
  };

  useEffect(loadStorageTheme, []);
  useEffect(saveAndActiveTheme, [isDarkMode]);

  const theme = useMemo(createCurrentTheme, [currentTheme]);
  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useCustomTheme() {
  const context = useContext(ThemeContext);
  return context;
}

export default ThemeProvider;
