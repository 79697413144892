import { ColorThemeProps } from '../types/theme.types';

export const lightTheme: ColorThemeProps = {
  menu: { main: '#FFFFFF', contrastText: '#024135' },
  menuActive: { main: '#f2f9f2', contrastText: '#024135' },
  menuHover: { main: '#22f92217' },
  navBar: { main: '#024135', contrastText: '#aeb2c4' },
  primary: { main: '#024135', contrastText: '#FFFFFF', light: '#4DB3A2' }, // Old light: #1d976c38
  secondary: { main: '#c2c2c2', contrastText: '#FFFFFF', light: '#c2c2c244' },
  background: { default: '#e6e6e6', paper: '#FFFFFF' },
  text: { primary: '#696969', disabled: '#CCC', secondary: '#363636' },
  textTitle: {
    primary: '#868686'
  },
  borderColor: 'lightgray',
  addFieldButton: { main: '#3e3e3e', contrastText: '#FFF' },
  scrollbarThumb: '#999999',
  action: {
    active: '#024135',
    hover: '#4DB3A2',
    selected: '#024135',
    disabled: '#00000042',
    disabledBackground: '#0000001f',
    focus: '#024135'
  }
};
