import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

const uploadApolloLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  credentials: 'include'
});

export const uploadLink = new ApolloLink((operation, forward) => {
  return uploadApolloLink.request(operation, forward);
});
