import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    position: fixed;
    z-index: 99;
  }

  .wrapper-content {
    display: flex;
    width: 100%;
    margin-top: 60px;
    height: calc(100vh - 60px);
    flex-direction: row;
    .main-content {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 8px 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    .wrapper-content {
      flex-direction: column;
      gap: 10px;
      height: auto;

      .main-content {
        height: auto;
      }
    }
  }
`;

export const ContentCard = styled.div`
  width: 100%;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  border: 1px solid ${(props) => props.theme.palette.borderColor};
  background: ${(props) => props.theme.palette.background.paper};

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    height: auto;
  }
`;
