import { ApolloClient } from '@apollo/client';
import { apolloCache } from './cache';

const clientGraphQL = new ApolloClient({
  cache: apolloCache,
  headers: {
    'Accept-Encoding': 'gzip;br'
  }
});

export default clientGraphQL;
