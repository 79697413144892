import { ColorThemeProps } from '../types/theme.types';

export const darkTheme: ColorThemeProps = {
  menu: { main: '#1e1e1ef5', contrastText: '#FFFFFF' },
  menuActive: { main: '#4b4b4b', contrastText: '#4DB3A2' },
  menuHover: { main: '#ffffff17' },
  navBar: { main: '#024135', contrastText: '#aeb2c4' },
  primary: { main: '#4DB3A2', contrastText: '#FFFFFF', light: '#4DB3A2' }, // Old light: rgba(218, 111, 69, 0.18)
  secondary: { main: '#FFFFFF', contrastText: '#141212' },
  background: { default: '#242424', paper: '#292929' },
  text: { primary: '#d4d4d4', disabled: '#afafaf', secondary: '#c9c9c9' },
  textTitle: {
    primary: '#f0f0f0'
  },
  borderColor: '#5b5b5b',
  addFieldButton: { main: '#3e3e3e', contrastText: '#FFF' },
  scrollbarThumb: '#666666',
  action: {
    active: '#4DB3A2',
    hover: '#024135',
    selected: '#4DB3A2',
    disabled: '#b4b4b4',
    disabledBackground: '#4c4b4b',
    focus: '#4DB3A2'
  }
};
