import Typography from '@mui/material/Typography';
import * as S from '../styles';
import { CircularProgress } from '@mui/material';

const Login = () => {
  return (
    <S.Container>
      <CircularProgress />
      <S.Title>
        <Typography variant="h2">Olá, estamos preparando o SGF para você!</Typography>
      </S.Title>
      <S.Description>Em alguns instantes esta página será redirecionada...</S.Description>
      <S.Description id="Versão01"></S.Description>
    </S.Container>
  );
};

export default Login;
