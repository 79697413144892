import { authenticationLink } from './authentication.link';
import { authorizationLink } from './authorization.link';
import { httpLink } from './http.link';
import { removeTypenameLink } from './remove-type-name.link';
import { uploadLink } from './upload.link';

export const ApolloLinks = {
  authenticationLink,
  authorizationLink,
  removeTypenameLink,
  uploadLink,
  httpLink
};
