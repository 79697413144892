import { DocumentNode, InMemoryCache } from '@apollo/client';
import { UserState } from '../types/user-state.type';
import { GET_EXP } from './queries/exp.query';
import { GET_LOCAL_STATE } from './queries/local-state.query';
import { GET_MENU } from './queries/menu.query';

export const apolloCache = new InMemoryCache({
  addTypename: false
});

apolloCache.writeQuery({
  query: GET_LOCAL_STATE,
  data: {
    user: JSON.parse(decodeURIComponent(localStorage.getItem('user') ?? '{}')),
    menu: JSON.parse(localStorage.getItem('menu') ?? '[]'),
    exp: localStorage.getItem('exp')
  }
});

apolloCache.writeQuery({
  query: GET_EXP,
  data: {
    exp: localStorage.getItem('exp')
  }
});

apolloCache.writeQuery({
  query: GET_MENU,
  data: {
    menu: JSON.parse(localStorage.getItem('menu') ?? '[]')
  }
});

export const isValid = (localstate: UserState) => {
  if (!localstate.exp || !localstate.user.userVerified || localstate.menu.length == 0) return false;
  if (localstate.exp < new Date()) return false;
  return true;
};

export const writeLocalState = (data: UserState) => {
  localStorage.setItem('user', encodeURIComponent(JSON.stringify(data.user)));
  localStorage.setItem('menu', JSON.stringify(data.menu));
  localStorage.setItem('exp', `${data.exp}`);
};

export const clearLocalState = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('menu');
  localStorage.removeItem('exp');
};

const writeLocalStorageState = <T extends Record<string, unknown>>(data: T): void => {
  if (!isValidObject(data)) return;
  for (const [key, value] of Object.entries(data).filter(([key]) => key !== '__typename')) {
    let serializedValue: string;

    if (isValidObject(value)) serializedValue = JSON.stringify(value);
    else serializedValue = String(value);

    localStorage.setItem(key, serializedValue);
  }
};

export const clearLocalStorageState = <T extends Record<string, unknown>>(data: T): void => {
  if (isValidObject(data)) Object.keys(data).forEach((key) => localStorage.removeItem(key));
};

export const writeCache = <T extends Record<string, unknown>>(
  data: T,
  query: DocumentNode,
  writeLocalStorage = true
): void => {
  if (writeLocalStorage) writeLocalStorageState(data);
  apolloCache.writeQuery({
    query: query,
    data
  });
};

const isValidObject = (data: Record<string, any> | any): boolean =>
  typeof data === 'object' && data !== null;
