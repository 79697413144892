import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  &.active-subMenu {
    background: ${(props) => props.theme.palette.menuHover.main};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 769px) {
    &.isOpenMenu {
      border-radius: ${(props) => props.theme.shape.borderRadius}px;
      font-weight: 600;
      border-left: 4px solid ${(props) => props.theme.palette.menuActive.contrastText};
      background: ${(props) => props.theme.palette.menuActive.main};
      color: ${(props) => props.theme.palette.menuActive.contrastText};
      margin-bottom: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    overflow-y: hidden;
  }
`;

export const SidebarLink = styled.div`
  display: flex;
  color: ${(props) => props.theme.palette.menu.contrastText};
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  list-style: none;
  height: 44px;
  text-decoration: none;
  border-left: 4px solid transparent;

  .icon {
    display: flex;
  }
  .label {
    display: block;
    flex: 1;
  }

  &.active-sub {
    margin-bottom: auto;
    font-weight: initial;
    border-left: 4px solid transparent;
  }

  &.active-menu {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    font-weight: 600;
    border-left: 4px solid ${(props) => props.theme.palette.menuActive.contrastText};
    background: ${(props) => props.theme.palette.menuActive.main};
    color: ${(props) => props.theme.palette.menuActive.contrastText};
  }

  div {
    display: flex;
    align-items: center;
  }

  .item-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
    transition: width 0.3s ease-in-out;

    .item {
      opacity: 1;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      transition: opacity 0.3s ease-in-out;
    }

    .hide-item {
      opacity: 0;
    }
  }

  &:hover {
    border-radius: ${(props) => props.theme.shape.borderRadius}px;
    background: ${(props) => props.theme.palette.menuHover.main};
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
`;

export const DropdownLink = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.palette.menu.contrastText};
  text-decoration: none;

  &:hover {
    background: ${(props) => props.theme.palette.menuHover.main};
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }

  &.active-subMenu {
    font-weight: 600;
    border-left: 4px solid ${(props) => props.theme.palette.menuActive.contrastText};
    color: ${(props) => props.theme.palette.menuActive.contrastText};
  }

  .item {
    opacity: 1;
    width: 200px;
    height: 44px;
    padding: 5px 20px;
    align-items: center;
    display: flex;
    transition: opacity 0.3s ease-in-out;
    flex-shrink: 0;
    line-height: normal;
  }

  .hide-item {
    height: 0;
    opacity: 0;
    padding: 0;
    overflow-y: hidden;
    padding-left: 5px;
  }

  @media only screen and (max-width: 768px) {
    .item {
      transition: all 0.3s ease-in-out;
    }

    .hide-item {
      padding: 5px 20px;
      height: 44px;
      background: none;
    }
  }
`;
