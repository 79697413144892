import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  background: ${(props) => props.theme.palette.navBar.main};
  color: ${(props) => props.theme.palette.primary.contrastText};

  @media only screen and (max-width: 768px) {
    padding: 30px 0 30px 0;
    height: 200px;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1400px;
  padding: 0 40px 0 40px;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    img {
      display: none;
    }

    img:first-child {
      display: flex;
      width: 120px;
    }
  }

  @media only screen and (max-width: 320px) {
    padding: 0;
  }
`;
