import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import FactCheckTwoToneIcon from '@mui/icons-material/FactCheckTwoTone';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsightsIcon from '@mui/icons-material/Insights';
import SettingsIcon from '@mui/icons-material/Settings';
import SpellcheckTwoToneIcon from '@mui/icons-material/SpellcheckTwoTone';
import WorkIcon from '@mui/icons-material/Work';
import { useMemo, useState } from 'react';
import MenuItem from '../menu/menu-item';
import { MenuItems, Props } from './menu.type';
import * as S from './styles';
import { useAuth } from '../../../../contexts/auth.context';
import { MenuArvore, MenuDaArvore } from '../../../../types/auth.types';
import PageRoutes from '../../../../constants/enum/page-routes.enum';

const Menu = ({ isOpen, onCloseMenu }: Props) => {
  const [showMenu, setShowMenu] = useState(Boolean);
  const { menu } = useAuth();

  const handleShowMenu = () => {
    setShowMenu(false);
    onCloseMenu(showMenu);
  };

  const getMenu = useMemo((): MenuItems[] => {
    const MenuIconMap = new Map<string, JSX.Element>([
      ['Configurações', <SettingsIcon key={1} />],
      ['Home', <HomeOutlinedIcon key={2} />],
      ['Planejamento', <WorkIcon key={3} />],
      ['Validação', <FactCheckTwoToneIcon key={4} />],
      ['Autorregularização', <SpellcheckTwoToneIcon key={5} />],
      ['Gerencial', <InsightsIcon key={6} />]
    ]);

    return menu?.map((item: MenuArvore) => {
      const isHomeMenu = item.nome === 'Home',
        subMenus = item.arvoreDeMenu?.map((subItem: MenuDaArvore) => {
          return {
            title: subItem.nome,
            path: subItem.url
          };
        });

      return {
        title: item.nome,
        path: isHomeMenu ? PageRoutes.HOME : item.url,
        icon: MenuIconMap.get(item.nome ?? ''),
        iconClosed: <ArrowDropDownIcon />,
        iconOpened: <ArrowDropUpIcon />,
        subNav: !isHomeMenu ? subMenus : undefined
      } as MenuItems;
    });
  }, [menu]);

  return (
    <S.Container className={isOpen ? 'showMenu' : undefined}>
      <S.MenuContainer>
        {getMenu?.map((item: MenuItems, index: number) => {
          return (
            <MenuItem onShowMenu={handleShowMenu} menuItem={item} key={index} isOpenMenu={isOpen} />
          );
        })}
      </S.MenuContainer>
    </S.Container>
  );
};
export default Menu;
