import { MenuArvore, User } from '../../types/auth.types';

export const DEFAULT_MENU: MenuArvore[] = [
  {
    nome: 'Home',
    url: '',
    menuID: 1,
    arvoreDeMenu: [
      {
        nome: 'Home',
        url: '/',
        menuID: 1,
        arvoreDeMenu: [
          {
            nome: 'arvoreMenu',
            url: null,
            menuID: 1,
            arvoreDeMenu: []
          }
        ]
      }
    ]
  }
];

export const DEFAULT_USER: User = {
  login: 'usuario',
  username: 'Usuário',
  name: 'Usuário',
  identificacao: '',
  email: 'usuario@teste.com',
  userVerified: false,
  unidade: 'Placeholder',
  grupo: 'Placeholder',
  isContribuinte: false,
  isCplanf: false,
  isCoordenador: false,
  isFiscal: false,
  isDev: false
};
