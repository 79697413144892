import { ApolloLink, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import clientGraphQL from '..';
import { useNotification } from '../../../modules/_shared/contexts/notification-provider.context';
import { ApolloLinks } from '../links';
import { ApolloSGFProviderProps } from './types/apollo-sgf-provider-props.type';

/**
 * @description Função feita para criação de apolloLinks que necessitam exibir algum componente na interface.
 * Inserção de apolloLinks no ApolloCiient.
 * Retorno do ApolloProvider com o atributo client que recebe o ApolloClient com as configurações.
 *
 * @param ApolloCustomProviderWithContextProps
 * @returns Container ApolloProvider
 * @author Rafael Vieira
 */
export function ApolloSGFProvider({ children }: ApolloSGFProviderProps) {
  const { notifyError } = useNotification();
  /**
   * @description errorLink é um ApolloLink para gerenciamento de exceções.
   * Exibição de notificações de exceções.
   *
   * @param ErrorHandler
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const errorLink = onError(({ graphQLErrors, networkError }): any => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error: GraphQLError) => {
        const message = error.extensions?.message
          ? String(error.extensions?.message)
          : error.message;
        notifyError(message);
      });
    }

    if (networkError) notifyError(networkError.message);
  });

  const links = ApolloLink.from([
    errorLink,
    ApolloLinks.uploadLink,
    ApolloLinks.removeTypenameLink,
    ApolloLinks.httpLink
  ]);

  clientGraphQL.setLink(links);

  return <ApolloProvider client={clientGraphQL}>{children}</ApolloProvider>;
}
