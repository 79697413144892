import {
  QueryHookOptions,
  SuspenseQueryHookOptions,
  useLazyQuery as useApolloLazyQuery,
  useQuery as useApolloQuery,
  useSuspenseQuery as useApolloSuspenseQuery
} from '@apollo/client';
import { IGraphQLOperationList } from '../../graphql/interfaces/operation.interface';

export function useQuery<T>(
  operations: IGraphQLOperationList,
  options: QueryHookOptions | undefined = undefined
) {
  const { data, loading, refetch, error } = useApolloQuery<T>(operations.operation, options);
  return { data, isLoading: loading, refetch, error };
}

export function useSuspenseQuery<T>(
  operations: IGraphQLOperationList,
  options: SuspenseQueryHookOptions | undefined = undefined
) {
  const { data, fetchMore } = useApolloSuspenseQuery<T>(operations.operation, options);
  return { data, fetchMore };
}

export function useLazyQuery<T>(
  operations: IGraphQLOperationList,
  options: QueryHookOptions | undefined = undefined
) {
  const [query, { data, loading, refetch }] = useApolloLazyQuery<T>(operations.operation, options);
  return { query, data, isLoading: loading, refetch };
}
