import { useEffect, useState } from 'react';
import Footer from './footer';
import Header from './header';
import Menu from './menu';
import * as S from './styles';
import { useAuth } from '../../../contexts/auth.context';
import { AuthStatus } from '../../../constants/enum/authStatus.enum';
import { useResponsive } from '../../../hooks/responsive.hook';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
}
const MainLayout = ({ children }: Props) => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(true);
  const { isMobile } = useResponsive();
  const { status } = useAuth();

  useEffect(() => {
    if (isMobile) setIsOpenMenu(false);
  }, [isMobile]);

  const handleToggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  return (
    <S.Container>
      <div className="header">
        <Header onOpenMenu={handleToggleMenu} />
      </div>
      <div className="wrapper-content">
        <div>
          {status === AuthStatus.Signed && (
            <Menu onCloseMenu={handleToggleMenu} isOpen={!!isOpenMenu} />
          )}
        </div>
        <div className="main-content">
          <S.ContentCard>{children}</S.ContentCard>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </S.Container>
  );
};

export default MainLayout;
