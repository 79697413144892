import { Switch } from '@mui/material';
import { useCustomTheme } from '../../../contexts/theme.context';
import * as S from './styles';

const CustomSwitch = () => {
  const { toggleDarkMode, isDarkMode } = useCustomTheme();

  return (
    <S.Container>
      <div className="label">
        <span>Escuro</span>
      </div>
      <Switch onChange={toggleDarkMode} checked={isDarkMode} id="themeSwitch" />
    </S.Container>
  );
};

export default CustomSwitch;
