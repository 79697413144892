import Typography from '@mui/material/Typography';
import * as S from '../styles';

const Logout = () => {
  return (
    <S.Container>
      <S.Title>
        <Typography variant="h2">Você foi desconectado!</Typography>
      </S.Title>
      <S.Description>
        Para efetuar um novo login, inicie uma nova sessão no{' '}
        <a href="https://eservicos.sefaz.ms.gov.br/">portal e-FAZENDA.</a>
      </S.Description>
    </S.Container>
  );
};

export default Logout;
