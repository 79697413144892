import { gql } from '@apollo/client';
import { IGraphQLOperationList } from '../../../../infra/graphql/interfaces/operation.interface';

const names = ['autorizar'];

const operation = gql`
  query autorizar($ticket: String!) {
    ${names[0]} (ticket: $ticket) {
      token
      autenticado
      mensagem
      expiration
      usuario {
        login
        name
        userName
        identificacao
        email
        unidade
        grupo
        isContribuinte
        isCPLANF
        isCoordenador
        isFiscal
        isDev
      }
      arvoreMenu {
        nome
        url
        menuID
        arvoreDeMenu {
          nome
          url
          menuID
          arvoreDeMenu {
            nome
            url
            menuID
          }
        }
      }
    }
  }
`;

export const GET_AUTORIZAR: IGraphQLOperationList = { names, operation };
