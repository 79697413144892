import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;

  .label {
    color: ${(props) => props.theme.palette.primary.contrastText};
  }

  @media only screen and (max-width: 768px) {
    .label {
      display: none;
    }
  }
`;
