import { ErrorBoundary } from 'react-error-boundary';
import { Suspense, ComponentType, useEffect, useCallback } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { useAuth } from '../modules/_shared/contexts/auth.context';
import PageRoutes from '../modules/_shared/constants/enum/page-routes.enum';

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ComponentType<any>;
}

const PrivateRoute = ({ component: Component, path, ...rest }: PrivateRouteProps) => {
  const { permissoes, exp, setExpired } = useAuth();
  const history = useHistory();

  const hasSessionExpired = useCallback(() => {
    const currentDate = new Date();
    const expire = exp ?? currentDate;

    return expire <= currentDate;
  }, [exp]);

  useEffect(() => {
    if (hasSessionExpired()) {
      history.push(PageRoutes.SESSION_EXPIRED, { shallow: true });
      setExpired();
    }
  }, []);

  const isPermissionGranted = () => permissoes.includes(path as string);

  return (
    <Suspense fallback={<></>}>
      <ErrorBoundary fallback={<></>}>
        <Route
          {...rest}
          path={path}
          component={() => {
            return isPermissionGranted() ? (
              <Component {...rest} />
            ) : (
              <Redirect to={PageRoutes.HOME} />
            );
          }}
        />
      </ErrorBoundary>
    </Suspense>
  );
};

export default PrivateRoute;
