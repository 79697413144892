import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 10vh;
  margin-top: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  flex-direction: column;
`;

export const Title = styled.span`
  margin-top: 10px;
  margin-bottom: 1vh;
`;

export const Description = styled.p`
  line-height: 18px;
  text-align: 'center';
`;
