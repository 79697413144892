import { createRoot } from 'react-dom/client';
import App from './App';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { GridChartsModule } from '@ag-grid-enterprise/charts-enterprise';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { AdvancedFilterModule } from '@ag-grid-enterprise/advanced-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import React from 'react';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY || '');

const container = document.getElementById('root');
if (!container) throw new Error('A configuração de root não foi encontrada.');

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
  CsvExportModule,
  GridChartsModule,
  ClipboardModule,
  ColumnsToolPanelModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  MasterDetailModule,
  MenuModule,
  RowGroupingModule,
  SetFilterModule,
  MultiFilterModule,
  AdvancedFilterModule,
  StatusBarModule
]);

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
