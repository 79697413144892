import Typography from '@mui/material/Typography';
import * as S from '../styles';
import { useEffect } from 'react';
import { clearLocalState } from '../../../../infra/apollo/cache';

const SessionExpired = () => {
  useEffect(() => {
    clearLocalState();
  }, []);

  return (
    <S.Container>
      <S.Title>
        <Typography variant="h2">Ops, sua sessão expirou!</Typography>
      </S.Title>
      <S.Description>
        Parece que a sua sessão terminou, inicie uma nova sessão no{' '}
        <a href="https://eservicos.sefaz.ms.gov.br/">portal e-FAZENDA.</a>
      </S.Description>
    </S.Container>
  );
};

export default SessionExpired;
