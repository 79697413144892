import './modules/_shared/locales/yup.pt-br.locale';
import GlobalStyle from './modules/_shared/style/global.style';
import ThemeProvider from './modules/_shared/contexts/theme.context';
import Login from './modules/home/pages/login';
import AppPages from './routes';
import { AuthProvider } from './modules/_shared/contexts/auth.context';
import { useEffect, useState } from 'react';
import { ApolloSGFProvider } from './infra/apollo/provider';
import { NotificationProvider } from './modules/_shared/contexts/notification-provider.context';

const App = () => {
  const page = window.location.href;
  const [isSignIn, setIsSignIn] = useState(true);
  const [hash, setHash] = useState<string | null>(null);

  useEffect(() => {
    firstRender();
  }, []);

  const firstRender = async () => {
    const hash = page.split('#/?id=')[1];
    if (!hash) {
      setIsSignIn(false);
      return;
    }

    setHash(hash);
  };

  const onLoginComplete = () => setIsSignIn(false);

  return (
    <ThemeProvider>
      <GlobalStyle />
      <NotificationProvider>
        <ApolloSGFProvider>
          <AuthProvider isSignIn={isSignIn} onLoginComplete={onLoginComplete} ticket={hash}>
            {isSignIn ? <Login /> : <AppPages />}
          </AuthProvider>
        </ApolloSGFProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
