import { useAuth } from '../modules/_shared/contexts/auth.context';
import AppRoutes from './public.routes';
import Unauthorized from '../modules/home/pages/unauthorized';
import { AuthStatus } from '../modules/_shared/constants/enum/authStatus.enum';
import SessionExpired from '../modules/home/pages/session-expired';
import Logout from '../modules/home/pages/logout';

const AppPages = () => {
  const { status } = useAuth();
  const app = () => {
    if (status === AuthStatus.Unauthorized) return <Unauthorized />;
    if (status === AuthStatus.Expired) return <SessionExpired />;
    if (status === AuthStatus.Logout) return <Logout />;
    return <AppRoutes />;
  };

  return app();
};

export default AppPages;
