import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useResponsive } from '../../../../../hooks/responsive.hook';
import * as S from '../menu-item/styles';

type SubMenuProps = {
  path: string;
  title: string;
};

type MenuProps = {
  path?: string;
  title: string;
  icon?: any;
  iconClosed: any;
  iconOpened: any;
  subNav?: SubMenuProps[];
};

type Props = {
  menuItem: MenuProps;
  onShowMenu: (isClose: boolean) => void;
  isOpenMenu: boolean;
};

const MenuItem: React.FC<Props> = ({ menuItem: item, onShowMenu, isOpenMenu }: Props) => {
  const [showMenu, setShowMenu] = useState(Boolean);
  const [subMenu, setSubMenu] = useState(false);
  const { isMobile } = useResponsive();
  const history = useHistory();
  const location = useLocation();

  const showSubMenu = () => {
    setSubMenu(!subMenu);
  };

  const isActive = (route: any) => {
    return location.pathname == route;
  };

  const subNavIsActive = (subsNav?: SubMenuProps[]) => {
    if (subsNav && subsNav.filter((s) => s.path == location.pathname).length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCloseMenu = () => {
    if (isMobile) {
      setShowMenu(false);
      onShowMenu(showMenu);
    }
  };

  const handleClickSideBarLink = () => {
    if (!isOpenMenu) {
      onShowMenu(true);
    }
    if (item.subNav) {
      return showSubMenu();
    }
    if (item?.path && item.path !== location.pathname) {
      history.push(item.path);
    }
    if (!item.subNav) {
      handleCloseMenu();
    }
  };

  const handleClickDropLink = (to: string) => {
    if (to !== location.pathname) {
      history.push(to);
    }
    handleCloseMenu();
  };

  return (
    <S.Container
      className={
        subMenu && !isOpenMenu && subNavIsActive(item.subNav)
          ? 'isOpenMenu'
          : subMenu
          ? 'active-subMenu'
          : undefined
      }
    >
      <S.SidebarLink
        className={subMenu ? 'active-sub' : isActive(item?.path) ? 'active-menu' : undefined}
        onClick={handleClickSideBarLink}
        id={`sideBarLink_${item.title}`}
      >
        <div className={isOpenMenu ? 'item-menu' : 'item-menu hide-item-menu'}>
          <span className="icon">{item.icon}</span>
          <div className={isOpenMenu ? 'item' : 'item hide-item'}>
            <span className="label">{item.title}</span>
            {item.subNav && subMenu ? item.iconOpened : item.subNav ? item.iconClosed : null}
          </div>
        </div>
      </S.SidebarLink>
      {subMenu &&
        item.subNav?.map((item, index) => {
          return (
            <S.DropdownLink
              onClick={() => handleClickDropLink(item.path)}
              key={index}
              className={isActive(item.path) && isOpenMenu ? 'active-subMenu' : 'hide-item'}
              id={`dropdownLink_${item.title}`}
            >
              <div className={isOpenMenu ? 'item' : 'item hide-item'}>
                <span>{item.title}</span>
              </div>
            </S.DropdownLink>
          );
        })}
    </S.Container>
  );
};

export default MenuItem;
