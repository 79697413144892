import * as S from '../footer/styles';

const Footer = () => {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <S.Container>
      <S.Content>
        <img src="/assets/img/logo-cotin-branco-coluna.png" width={80} />
        <img src="/assets/img/logo-sefaz-aumentado-branco.png" />
        <img src="/assets/img/ucqi-mini.png" />
        <span>© {getCurrentYear()} COTIN - Todos direitos reservados</span>
      </S.Content>
    </S.Container>
  );
};

export default Footer;
