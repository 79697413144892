import Typography from '@mui/material/Typography';
import * as S from '../styles';

const Unauthorized = () => {
  return (
    <S.Container>
      <S.Title>
        <Typography variant="h2">Ops, você não está autorizado!</Typography>
      </S.Title>
      <S.Description>Parece que você não está autorizado a acessar o sistema SGF.</S.Description>
      <S.Description>
        Inicie uma nova sessão no <a href="https://eservicos.sefaz.ms.gov.br/">portal e-FAZENDA.</a>
      </S.Description>
    </S.Container>
  );
};

export default Unauthorized;
